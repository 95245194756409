a.ph-warning {
  margin-top: -(@ph-footer-height + 160px);

  background: @ph-bg-danger;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    background: @ph-danger-highlight-color;
  }
}

.ph-icon-margin {
  margin-right: 5px;
}
