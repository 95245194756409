.ph-margin-discussion, margin-discussion-draft {
  position: absolute;
  left: (@grid-gutter-width / 2);
  right: (@grid-gutter-width / 2);
}

.ph-margin-discussion-animate {
  transition: top 0.35s ease, opacity .6s ease;
}

margin-discussion img {
  max-width: 100%;
}

margin-discussion-draft {
  transition: opacity 0.25s 0.25s ease;
  &.ng-enter, &.ng-leave.ng-leave-active {
    opacity: 0;
  }
  &.ng-enter.ng-enter-active, &.ng-leave {
    opacity: 1;
  }
  &.ng-leave {
    transition-delay: 0;
  }
}

.ph-margin-discussion-fade {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.ph-margin-container {
  position: relative;
  box-shadow: @ph-shadow;
  margin-left: (@ph-margin-avatar-size + @ph-margin-arrow-size +
    @ph-margin-avatar-arrow-padding);

  // required for shadows to stay below adjacent elements
  // WARNING: disabled because it breaks dropdown menus
  //position: relative;
  //z-index: 1;
}

.ph-margin-container, margin-cluster {
  .ph-channel-description {
    position: absolute;
    top: -3px;
    width: 100%;
    font-size: @ph-badge-font-size;
    text-align: center;
    background: @ph-highlight-color;
    color: white;
    line-height: 20px;
    transition: top .6s ease;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    a {
      color: white;
    }

    &.ph-channel-private {
      // todo: fix color
      background: @ph-channel-private-bgcolor;
    }

    &.ph-channel-description-sticky {
      top: -19px;
    }
  }

  &:hover .ph-channel-description {
    top: -19px;
  }
}

.ph-margin-row {
  position: relative; // required for ph-margin-avatar
}

.ph-margin-avatar {
  position: absolute;
  // compensate margin-left of margin-container
  left: -(@ph-margin-avatar-size + @ph-margin-arrow-size +
    @ph-margin-avatar-arrow-padding);
  top: 0;
}

.ph-margin-arrow-container {
  .ph-arrow-container(
    @ph-margin-arrow-size,
    @ph-margin-body-bgcolor,
    @ph-margin-border-color
  );
  position: absolute;
  height: @ph-margin-avatar-size;
}

.ph-margin-base-row {
  background-color: @ph-margin-body-bgcolor;

  // position base-row above channel flag
  position: relative;

  > .ph-margin-arrow-container {
    .ph-arrow-container(
      @ph-margin-arrow-size,
      @btn-default-bg,
      @ph-margin-border-color
    );
  }
}

@ph-margin-reply-bgcolor: darken(@btn-default-bg, 5%);
.ph-margin-reply {
  background-color: @ph-margin-reply-bgcolor;
  > .ph-margin-arrow-container {
    .ph-arrow-container(
      @ph-margin-arrow-size,
      @ph-margin-reply-bgcolor,
      @ph-margin-border-color
    );
  }
}

.ph-margin-row-content {
  padding: 8px;
  min-height: @ph-margin-avatar-size;
  word-wrap: break-word;
}

.ph-margin-row-border-top {
  border-top: 1px @ph-margin-border-color solid;
}

.ph-margin-title {
  font-weight: bold;
}

.ph-margin-info {
  .text-muted();
  font-size: smaller;
}

.ph-margin-textarea {
  padding: 5px 7px;
}

margin-discussion kramjax p {
  margin: 0 0 5px;
}

margin-discussion kramjax .MathJax_Display {
  margin: 5px 0;
}

.ph-margin-body {
  position: relative;
  overflow: hidden;
}


// includes the avatars in the collapse animation
.ph-margin-collapse {
  margin-left: -(@ph-margin-avatar-size + @ph-margin-arrow-size +
    @ph-margin-avatar-arrow-padding);
  padding-left: (@ph-margin-avatar-size + @ph-margin-arrow-size +
    @ph-margin-avatar-arrow-padding);
}

.ph-margin-unknown-avatar-container {
  display: table;
  width: @ph-margin-avatar-size;
  height: @ph-margin-avatar-size;
}

.ph-margin-unknown-avatar {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 36px;
  line-height: 1;
}
