/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
margin-cluster-pane {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 25%;
  z-index: 1028;
  background-color: white;
  box-shadow: 0 0 5px 0px rgba(64, 64, 64, 0.3);
  display: flex;
  flex-direction: column;
}
margin-cluster-pane > .ph-component-header > button:active,
margin-cluster-pane > .ph-component-header > button:focus {
  outline: none !important;
}
margin-cluster-pane > .ph-component-body {
  flex-grow: 1;
  width: 100%;
  padding: 30px 15px;
  overflow-y: auto;
}
margin-cluster-pane > .ph-component-body > margin-discussion {
  display: block;
  margin-bottom: 30px;
}
