.badge-default {
  background-color: @label-default-bg;
}

.badge-primary {
  background-color: @label-primary-bg;
}

.badge-success {
  background-color: @label-success-bg;
}

.badge-info {
  background-color: @label-info-bg;
}

.badge-warning {
  background-color: @label-warning-bg;
}

.badge-danger {
  background-color: @label-danger-bg;
}
