/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
onboarding {
  display: block;
  margin: 30px 0;
}
onboarding onboarding-bookmark,
onboarding onboarding-channel,
onboarding onboarding-profile {
  display: block;
}
onboarding .ph-component-step {
  position: relative;
  min-height: 150px;
  padding: 15px 30px;
  border-left: 6px solid;
  margin-left: 19px;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0 0 5px 0px rgba(64, 64, 64, 0.3);
  transition: 0.8s ease opacity, 0.8s ease border-left-color;
}
onboarding .ph-component-step > .ph-component-step-number {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  left: -25px;
  margin-top: -25px;
  border-radius: 50%;
  border: #fafafa solid 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  transition: 0.8s ease background-color;
}
onboarding .ph-component-step.ph-component-step-complete {
  border-left-color: #49dc35;
}
onboarding .ph-component-step.ph-component-step-complete > .ph-component-step-number {
  background-color: #49dc35;
}
onboarding .ph-component-step.ph-component-step-incomplete {
  border-left-color: hsl(0, 0%, 90%);
}
onboarding .ph-component-step.ph-component-step-incomplete > .ph-component-step-number {
  background-color: hsl(0, 0%, 90%);
}
onboarding .ph-component-step.ph-component-step-active {
  border-left-color: #29abe2;
}
onboarding .ph-component-step.ph-component-step-active > .ph-component-step-number {
  background-color: #29abe2;
}
onboarding .ph-component-step.ph-component-step-inactive {
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
}
onboarding .ph-component-buttons {
  margin-top: 15px;
}
onboarding .ph-btn-skip {
  color: #777777;
}
