// sticky footer, see http://getbootstrap.com/examples/sticky-footer-navbar/

html {
  // sticky footer
  position: relative;
  min-height: 100%;
}
body {
  // this property is set dynamically via js according to the actual height of
  // the footer, see index.html.
  padding-bottom: @ph-footer-height;

  > footer {
    // sticky footer
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: @ph-footer-height;

    .ph-highlight-border-bottom();
  }
}

.ph-footer {
  background-color: @gray;
  color: @body-bg;
  padding-top: 2 * @padding-large-horizontal;
  padding-bottom: @padding-large-horizontal;

  // copyright
  .ph-copyright {
    color: @ph-footer-copyright-color;
  }

  // links
  a {
    color: @ph-footer-link-color;
    &:hover {
      text-decoration: none;
      color: @ph-footer-link-hover-color;
    }
  }
}
