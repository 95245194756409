margin-link {
  display: inline-block;
}

.ph-margin-link {
  position: relative;
  width: @ph-margin-controls-height;
  height: @ph-margin-controls-height;
  border-radius: @ph-margin-controls-height / 2;
  border: none;

  // center icon
  padding: 0;
  line-height: @ph-margin-controls-height;

  box-shadow: @ph-shadow;

  transition: opacity ease 0.5s, top ease 0.5s, bottom ease 0.5s;

  // animate top
  &.ph-margin-link-top {
    top: 0;
    &.ng-hide {
      top: -@ph-margin-controls-height - @grid-gutter-width;
    }
  }

  // animate bottom
  &.ph-margin-link-bottom {
    bottom: 0;
    &.ng-hide {
      bottom: -@ph-margin-controls-height - @grid-gutter-width;
    }
  }

  // display old value when hiding (includes animation)
  &.ng-hide-add .ph-margin-link-current {
    display: none;
  }

  // display current value when not hiding
  &:not(.ng-hide-add) .ph-margin-link-previous {
    display: none;
  }
}

.ph-margin-link-badge {
  .badge(@ph-badge-font-size, @ph-badge-padding, @ph-base-color, @ph-badge-color);
  position: absolute;
  right: -3px;
  top: -3px;
}
