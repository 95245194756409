.jumbotron,
.container .jumbotron,
.container-fluid .jumbotron {
  margin-bottom: 0;
  padding: @jumbotron-padding 0;
  background-color: none;

  h1 {
    font-weight: 900;
  }
}
