pdf-full {
  display: block;
}

.ph-pdf-page {
  display: block;
  box-shadow: @ph-shadow;
  margin-bottom: 15px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: white;
  position: relative;
  .ph-selection(none);

  & > .ph-pdf-canvas {
    position: absolute;
    left: 0;
    top: 0;
    right:0;
    bottom: 0;
    overflow: hidden;
  }

  & > .ph-pdf-text {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    /* set opacity instead of transparent background color because the
     * ::selection selector is not yet standardized and not fully supported
     * see https://developer.mozilla.org/en-US/docs/Web/CSS/::selection
     */
    opacity: 0.6;

    // do not lighten up colors by highlighting, e.g., black text remains black
    // although we the highlight is a layer on top of the text.
    mix-blend-mode: multiply;

    /* override bootstrap's line-height, cf.
     * <https://github.com/mozilla/pdf.js/issues/6614> */
    line-height: 1.0;

    // allow text selections
    .ph-selection(text);

    // style text selections
    & ::selection {
      background: @ph-highlight-color;
    }
    & ::-moz-selection {
      background: @ph-highlight-color;
    }

    & > div {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
      z-index: 1000;
    }
  }

  & > .ph-pdf-annotations {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    // TODO: this may hide the selection tooltip (ctrl+alt/cmd)
    overflow: hidden;

    .ph-selection(none);
    & > section {
      position: absolute;
      pointer-events: all;
      box-sizing: content-box;
      & > a {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    &.ph-no-interaction > section {
      pointer-events: none;
    }
  }

  & > .ph-pdf-highlights {
    .ph-selection(none);

    // do not lighten up colors by highlighting, e.g., black text remains black
    // although we the highlight is a layer on top of the text.
    mix-blend-mode: multiply;

    .ph-pdf-highlight {
      opacity: 0.4;

      transition: background-color 0.3s ease;
      background-color: @ph-text-highlight-color;
      &.ph-pdf-highlight-emphasize {
        background-color: @ph-highlight-color;
        z-index: 1;
      }
    }
    pdf-highlight.ph-pdf-highlight-search {
      .ph-pdf-highlight {
        background-color: lighten(@ph-badge-bg-color, 10%);
      }
    }
  }
}

// animation helper
// makes sure that the canvas is properly scaled during the animation
.ph-document-text.ph-sidenav-open-add .ph-pdf-canvas canvas,
.ph-document-text.ph-sidenav-open-remove .ph-pdf-canvas canvas,
pdf-full.ph-pdf-resize-active .ph-pdf-canvas canvas {
  width: 100% !important;
  height: 100% !important;
}

.ph-popup-button {
  position: absolute;
  margin: 2px;
  color: @link-color;
  &:hover {
    color: @link-hover-color;
  }
}
