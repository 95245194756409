comment, new-reply, .ph-comment-row {
  display: flex;
  word-wrap: break-word;
  align-items: flex-start;
  > a {
    min-width: 80px;
  }
  > .panel {
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    .ph-md-margin-left;
  }
}

.ph-comment-body {
  resize:vertical;
}

.ph-comment-help {
  font-size:smaller;
}
