/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
file-select-button {
  display: block;
}
file-select-button > label.drop-zone {
  padding: 30px 12px;
  border-width: 5px;
  border-style: dashed;
  white-space: normal;
}
file-select-button > label.drop-zone.drag-active {
  border-color: #999999;
}
file-select-button > label.drop-zone > ng-transclude {
  display: block;
}
file-select-button > label.drop-zone > input[type="file"] {
  display: none;
}
