document-sidenav-outline-item {
  display: flex;

  .ph-component-body {
    margin-left: 0.5em;
    overflow: hidden;

    .ph-component-link-container {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0.2em 0;
    }

    document-sidenav-outline-item {
      font-size: 90%;
    }
  }
}
