/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
document-sidenav-search .form-group {
  position: relative;
}
document-sidenav-search .form-group input {
  font-family: FontAwesome, Roboto, sans-serif;
}
document-sidenav-search .ph-component-controls {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 6px;
}
document-sidenav-search .ph-component-controls .ph-component-counters {
  font-size: 80%;
  color: #777777;
  vertical-align: middle;
  cursor: default;
  user-select: none;
}
document-sidenav-search .ph-component-controls .ph-component-separator {
  border-right: 1px solid #777777;
  margin: 0 6px;
  vertical-align: middle;
}
document-sidenav-search .ph-component-controls > button {
  padding: 6px;
  height: 100%;
}
