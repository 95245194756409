/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
onboarding-bookmark .ph-component-document {
  display: flex;
  align-items: flex-start;
}
onboarding-bookmark .ph-component-document.ng-hide-add,
onboarding-bookmark .ph-component-document.ng-leave {
  transition: all 0.8s ease;
  overflow: hidden;
}
onboarding-bookmark .ph-component-document.ng-hide-TODO,
onboarding-bookmark .ph-component-document.ng-leave {
  max-height: 100px;
}
onboarding-bookmark .ph-component-document.ng-hide,
onboarding-bookmark .ph-component-document.ng-leave.ng-leave-active {
  max-height: 0;
}
onboarding-bookmark .ph-component-document.ng-hide-remove,
onboarding-bookmark .ph-component-document.ng-enter {
  transition: all 0.8s ease;
  overflow: hidden;
}
onboarding-bookmark .ph-component-document.ng-hide,
onboarding-bookmark .ph-component-document.ng-enter {
  max-height: 0;
}
onboarding-bookmark .ph-component-document.ng-enter.ng-enter-active {
  max-height: 100px;
}
onboarding-bookmark .ph-component-document-controls {
  margin: 0 10px;
}
onboarding-bookmark .ph-component-document-body {
  flex-grow: 1;
  overflow: hidden;
  margin-bottom: 5px;
}
onboarding-bookmark .ph-component-title {
  margin: 5px 0;
}
onboarding-bookmark .ph-component-title mathjax {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
onboarding-bookmark .ph-component-document-author-year {
  display: flex;
  margin-bottom: 5px;
}
onboarding-bookmark .ph-component-document-author {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
onboarding-bookmark .ph-component-document-bib {
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
