.ph-textarea-elastic {
  overflow-y: hidden;
  resize: none;
}

.ph-textarea-small {
  min-height: 2em;
}

.ph-textarea-large {
  min-height: 6em;
}
