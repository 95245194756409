.ph-discussion-title {
  width: auto;
  display: inline;
}

.ph-subscription-button {
  width: 100%;
}

.ph-subscription-text {
  font-size: x-small;
}

.ph-discussion-subtitle {
  color:#888;
}

.ph-discussion-replies-counter {
  color:#888;
}

.ph-discussion-not-auth-user {
  text-align: center;
  min-width: 80px;
  width: 80px;
}
