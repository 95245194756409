/* http://edward.oconnor.cx/2007/08/tex-poshlet */
.ph-tex sub, .ph-latex sub, .ph-latex sup {
  text-transform: uppercase;
}
.ph-tex sub, .ph-latex sub {
  vertical-align: -0.5ex;
  margin-left: -0.1667em;
  margin-right: -0.125em;
}
.ph-tex, .ph-latex, .ph-tex sub, .ph-latex sub {
  font-size: 1em;
}
.ph-latex sup {
  font-size: 0.85em;
  vertical-align: 0.15em;
  margin-left: -0.36em;
  margin-right: -0.15em;
}
