.ph-dropdown-large {
  .btn, .dropdown-menu {
    font-size: @font-size-base;
    width: 100%;
    max-height: 345px;
    box-shadow: none;

    > li {
      > a {
        padding-top: @padding-base-vertical*2;
        padding-bottom: @padding-base-vertical*2;
      }
      &.active {
        > a, a:hover {
          background: @ph-highlight-color;
          .ph-channel-icon {
            color: @ph-white;
          }
        }
        &.ph-private-channel {
          > a, a:hover {
            background: @ph-channel-private-bgcolor;
            .ph-channel-icon > .fa-circle {
              color: @ph-white;
            }
            .ph-channel-icon > .fa-inverse {
              color: @ph-channel-private-bgcolor;
            }
          }
        }
      }

      &.divider {
        margin: 0;
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    margin: 3px 0;
    box-shadow: @ph-shadow;
    overflow-y: auto;
  }
}

.ph-channel-icon-public {
  color: @ph-highlight-color;
}

.ph-channel-icon-private > .fa-circle {
  color: @ph-channel-private-bgcolor;
}

.ph-dropdown-flex {
  .ph-selected-channel {
    flex-grow: 1;
    overflow: hidden;
    padding-right: @padding-base-vertical;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .ph-channel-selector-channel {
    &.btn-group {
      display: flex;
      position: initial;
    }
  }
}
