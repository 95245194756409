/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
margin-cluster {
  position: absolute;
  left: 15px;
  right: 15px;
}
margin-cluster > avatar {
  position: absolute;
  top: 0;
  left: 0;
}
margin-cluster > .ph-component-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 38px;
  border: none;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
}
margin-cluster > .ph-component-circle:active,
margin-cluster > .ph-component-circle:focus {
  outline: none !important;
}
margin-cluster > .ph-component-circle > div {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
margin-cluster > .ph-component-body {
  margin-left: 50px;
  box-shadow: 0 0 5px 0px rgba(64, 64, 64, 0.3);
  position: relative;
}
margin-cluster > .ph-component-body .ph-component-shadow-1 {
  position: absolute;
  top: 3.75px;
  bottom: -3.75px;
  left: 3.75px;
  right: -3.75px;
  background-color: white;
  box-shadow: 0 0 5px 0px rgba(64, 64, 64, 0.3);
  z-index: -1;
}
margin-cluster > .ph-component-body .ph-component-shadow-2 {
  position: absolute;
  top: 7.5px;
  bottom: -7.5px;
  left: 7.5px;
  right: -7.5px;
  background-color: white;
  box-shadow: 0 0 5px 0px rgba(64, 64, 64, 0.3);
  z-index: -2;
}
margin-cluster > .ph-component-body .ph-margin-row-content {
  background-color: white;
  position: relative;
}
margin-cluster > .ph-component-body .ph-component-body-container {
  max-height: 4em;
  overflow: hidden;
}
margin-cluster > .ph-component-body .ph-component-body-container .ph-component-discussion-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
margin-cluster > .ph-component-body .ph-component-body-container .ph-component-discussion-body > :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
margin-cluster > .ph-component-body .ph-component-body-container .ph-component-discussion-body > :not(:first-child) {
  display: none;
}
