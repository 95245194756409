/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
document-item-metadata-modal .ph-author {
  display: flex;
  align-items: center;
}
document-item-metadata-modal .ph-author.ng-hide-add,
document-item-metadata-modal .ph-author.ng-leave {
  transition: all 0.8s ease;
  overflow: hidden;
}
document-item-metadata-modal .ph-author.ng-hide-TODO,
document-item-metadata-modal .ph-author.ng-leave {
  max-height: 40px;
}
document-item-metadata-modal .ph-author.ng-hide,
document-item-metadata-modal .ph-author.ng-leave.ng-leave-active {
  max-height: 0;
}
document-item-metadata-modal .ph-author.ng-hide-remove,
document-item-metadata-modal .ph-author.ng-enter {
  transition: all 0.8s ease;
  overflow: hidden;
}
document-item-metadata-modal .ph-author.ng-hide,
document-item-metadata-modal .ph-author.ng-enter {
  max-height: 0;
}
document-item-metadata-modal .ph-author.ng-enter.ng-enter-active {
  max-height: 40px;
}
document-item-metadata-modal .ph-author-name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
