.uib-datepicker-popup {
  padding: 5px;
}

.uib-datepicker {
  // remove annoying outline
  :hover, :focus {
    outline: none;
  }

  // use font awesome for glyphicons used in uib template
  .glyphicon {
    .fa();
  }
  .glyphicon-chevron-left:before {
    content: @fa-var-chevron-left;
  }
  .glyphicon-chevron-right:before {
    content: @fa-var-chevron-right;
  }
}
