.ph-documents-columns {
  margin-top: @ph-subnav-height;
  padding-top: @grid-gutter-width/2;
}

.ph-document-subnav {
  .ph-sidenav-content(12, 9);
}

.ph-document-text {
  .ph-sidenav-content(9, 6);
}

.ph-distributor-logo {
  height: 2em;
}
