// fixed navbar padding
body {
  padding-top: @navbar-height + @ph-navbar-border + @ph-border-height + @ph-subnav-height;
}

.ph-undo-navbar-padding {
  margin-top: -(@navbar-height + @ph-navbar-border + @ph-border-height + @ph-subnav-height);
}

// larger avatar does not enlarge button/link
.navbar .ph-avatar {
  width: 38px;
  height: 38px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 0.5em;
}

.ph-title-logo {
  height: 100%;
}

/* HEADER */
/* Navigation */
.navbar {
  border-top: @ph-navbar-border solid @ph-highlight-color;
  padding: @ph-navbar-default-padding;
  border-bottom: 0;

  // flicker fix on chrome http://stackoverflow.com/a/22224884/1219479
  -webkit-backface-visibility: hidden;

  // Changing size of navbar-form
  &-form {
    min-width: 450px;
    .form-control {
      width: 100%;
    }
    @media (max-width: @screen-sm-max){
      min-width: 300px
    }
  }

  @media (max-width: @screen-xs-max){
    .navbar-collapse, .navbar-form {
      border: none;
    }
  }
}

.ph-navbar-shadow {
  z-index: @zindex-navbar-fixed - 2;
  border-bottom: 1px solid #bfbfbf;
  box-shadow: 0 -10px 5px 10px rgba(64, 64, 64, 0.3);
  width: 100%;
  position: fixed;
  top: @navbar-height + @ph-navbar-border;
}

.navbar-brand {
  padding-top: 7px;
  padding-bottom: 7px;

  // Change size of PaperHiver logo on XS-Viewport, so it does not breaks up
  @media (max-width: @screen-xs-max){
    max-width: 210px;
    width: auto;
    height: 60px;
    padding-top: 12px;
    padding-bottom: 12px;
    > img {

    }
  }
}


@media (max-width: @screen-xs-max){
  header .nav {
    margin: 0;
    > li {
      border-top: 1px solid #fff;

      > a {
      }
    }
  }
  header .ng-scope {
    margin-bottom: 15px;
    > li {
      .ph-avatar {
        float: right;
        position: relative;
        top: 5px;
        //margin-top: 10px;
      }
    }
    p {
      text-align: right;
      margin-right: 0;
    }
  }
}
/*.nav.navbar-nav.navbar-right {
  margin: 8px 0;
}

.navbar-nav > li > a {
  padding: @nav-link-padding;
}
*/

.ph-navbar-search {
  // set dropdown to same width as input field
  position: relative;
  width: 100%;
  & > .dropdown-menu {
    max-width: 100%;
    // shorten text and add "..."
    & > li > a {
      .text-overflow();
      padding: 20px 28px;
      cursor: pointer;
    }
  }
}

.ph-navbar-alert {
  margin-bottom: 0;
}
