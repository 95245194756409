.ph-bg-full {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &-fiber {
    background-image: url(../../static/img/fiber.jpg);
  }
  /* remove jumbotron default background */
  .jumbotron {
    background: none;
  }
  &-dark {
    color: white;
  }
}

.ph-padding-20 {
  padding: 20px;
}

.ph-bg-rocket {
  // hide video on mobile
  @media (max-width: @screen-sm-max) {
    display: none;
  }
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ph-bg-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.ph-bg-hexagons {
  background-image: url('../../static/img/background_pattern_light.svg');
  background-color: white;
}

.ph-intro-panels {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ph-intro-panel {
  min-width: 250px;
  width: 100%;
  @media (min-width: @screen-md-min) {
    width: 40%;
  }
  background-color: rgba(255, 255, 255, 0.7);
  color: @text-color;
}

.ph-intro-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 0;
  position: relative;
  @media (min-width: @screen-md-min) {
    padding: 90px 0;
  }
}

.ph-intro-arrow {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  &-bottom {
    bottom: 20px;
  }
  &-bright > a {
    color: white;
    &:hover {
      color: #b3b3b3;
      text-decoration: none;
    }
  }
}

.ph-intro-icon {
  margin-top: 22px;
  height: 160px;
  max-width: 100%;
}

.ph-intro-icon-sm {
  margin-top: 22px;
  margin-left: 20px;
  margin-right: 20px;
  height: 120px;
  max-width: 100%;
  @media (max-width: @screen-md-min) {
    margin-left: 0;
    margin-right: 0;
  }
}
