@ph-timeline-arrow-size: 14px;
@ph-timeline-icon-size: 50px;
@ph-timeline-icon-offset-y: 16px;
@ph-timeline-line-width: 3px;
@ph-timeline-line-color: #eee;

.ph-timeline {
  list-style: none;
  padding: 0;
  margin-bottom: @ph-timeline-icon-size + 2 * @ph-timeline-icon-offset-y;
  position: relative;
}

.ph-timeline:before {
  top: @ph-timeline-icon-offset-y + @ph-timeline-icon-size / 2;
  bottom: 0;
  position: absolute;
  content: " ";
  width: @ph-timeline-line-width;
  background-color: @ph-timeline-line-color;
  margin-left: @ph-timeline-icon-size / 2 - @ph-timeline-line-width / 2;
}

.ph-timeline:after {
  bottom: -@ph-timeline-icon-size;
  height: @ph-timeline-icon-size;
  position: absolute;
  content: " ";
  width: @ph-timeline-line-width;
  background: linear-gradient(@ph-timeline-line-color, rgba(0, 0, 0, 0));
  margin-left: @ph-timeline-icon-size / 2 - @ph-timeline-line-width / 2;
}

.ph-timeline > li {
  position: relative;
}

.ph-timeline > li > .ph-timeline-panel {
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  min-height: 2 * @ph-timeline-icon-offset-y + @ph-timeline-icon-size;
}

.ph-timeline > li > .ph-timeline-badge {
  color: #ffffff;
  width: @ph-timeline-icon-size;
  height: @ph-timeline-icon-size;
  line-height: @ph-timeline-icon-size;
  position: absolute;
  top: @ph-timeline-icon-offset-y;
  background-color: @ph-highlight-color;
  border-radius: 50%;
  text-align: center;
  font-size: @ph-timeline-icon-size / 2;
  > img {
    vertical-align: middle;
    height: @ph-timeline-icon-size * 0.6;
  }
}
.ph-timeline > li.ph-timeline-item > .ph-timeline-panel {
  margin-left: @ph-timeline-icon-size + 1.5 * @ph-timeline-arrow-size;
}

.ph-timeline-arrow-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 2 * @ph-timeline-icon-offset-y + @ph-timeline-icon-size;
  .ph-arrow-container(@ph-timeline-arrow-size, @panel-bg, @panel-default-border);
}

.ph-activity-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ph-activity {
  flex-grow: 2;
}

.ph-activity-loading {
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: @screen-sm-min) {
  .ph-timeline-date {
    float: right;
    margin-left: 2em;
  }
}
