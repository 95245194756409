/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
search .ph-search-hit > h4 {
  margin-bottom: 4.4px;
}
search .ph-search-hit > p {
  margin-bottom: 4.4px;
}
