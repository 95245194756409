.ph-highlight-layer{
  position: absolute;
  top: 0;
}

.ph-highlight-target-bbox {
  position: absolute;
  border: 2px transparent solid;
  border-radius: 3px;
  padding: 1px;
  transition: border-color 0.5s ease;
  div {
    position: absolute;
  }
}

.ph-highlight-target-bbox.ph-highlight-target-bbox-active {
  border-color: black;
}

.ph-highlight-target {
  position: absolute;
  background-color: rgba(255, 255, 0, 0.4);
  z-index: 500; // must be between z-index of PDF canvas and PDF text layer
  .ph-selection(none);
}
