/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
document-sidenav-pagination .ph-component-current input,
document-sidenav-pagination .ph-component-total {
  text-align: center;
  padding: 3px;
  font-size: 80%;
  height: 25px;
}
.ph-sidenav-small document-sidenav-pagination .ph-component-current {
  margin: 6px;
}
.ph-sidenav-small document-sidenav-pagination .tooltip {
  white-space: nowrap;
}
