@ph-popup-border: 4px solid @ph-highlight-color;

.popover {
  z-index: @zindex-navbar-fixed + 1;
  &.bottom {
    border-bottom: @ph-popup-border;
  }
  &.top, &.left, &.right {
    border-top: @ph-popup-border;
  }
}
