.ph-login-icon {
  width: 18px;
  height: 18px;
  vertical-align: baseline;
}

.ph-login-separator {
  color: #E0E0E0;
}

.ph-login-line-separator {
  width: 1px;
  background-color: #E0E0E0;
  height: 12em;
}

.ph-flex-container {
  display: flex;
  justify-content: center;
}

.ph-flex-item {
  align-self: center;
  flex: 3;
}

.ph-flex-item-1 {
  align-self: center;
  flex: 1;
}

.ph-inner-flex-container {
  display: flex;
  flex-direction: column;
}

.ph-inner-flex-item {
  align-self: center;
  flex: 1;
}

/* mobile */
@media (max-width: @screen-xs-max) {
  .ph-flex-container {
    flex-direction: column;
    margin-top: 2em;
  }
  .ph-inner-flex-container {
    flex-direction: row;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .ph-login-line-separator {
    height: 1px;
    width: 8em;
    flex: 2;
  }
  .ph-login-separator {
    text-align: center;
    flex: 1;
  }
}

a.ph-forgot-password {
  position: relative;
  top: -28px;
  float: right;
  margin-right: 12px;
  cursor: pointer;
  text-decoration: none;
}
