document-sidenav {
  > * {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: @zindex-navbar-fixed - 2;

    background: @ph-white;
  }

  .btn, .dropdown-menu {
    font-size: @font-size-base - 2;
  }

  .btn:focus, .btn:active:focus {
    outline: none;
  }
}

.ph-sidenav-large {
  width: 25%; // TODO: via bootstrap mixin?
  min-width: 250px;
  overflow-x: hidden;
  overflow-y: auto;;
}

.ph-sidenav-small {
  width: @ph-sidenav-small-width;
}

// animation for sidenav
.ph-sidenav-large, .ph-sidenav-small {
  box-shadow: @ph-shadow;
  &.ng-hide {
    box-shadow: none;
    transform: translateX(-100%);
  }
  &.ng-hide-add {
    transition:
      transform @ph-animation-duration ease-in-out,
      box-shadow @ph-animation-duration ease-in-out;
  }
  &.ng-hide-remove {
    transition:
      transform @ph-animation-duration ease-in-out @ph-animation-duration,
      box-shadow @ph-animation-duration ease-in-out @ph-animation-duration;
  }
}

.ph-sidenav-body {
  padding: @ph-sidenav-small-width (@grid-gutter-width/2) 30px;
  height: 100%;
  overflow: auto;
}

// mixin for content that should not be hidden beneath the sidenav
.ph-sidenav-content(@columns, @columns-sidenav-open) {
  .make-md-column(@columns);
  margin-left: 0;
  padding-left: @ph-sidenav-small-width + @grid-gutter-width / 2;

  &.ph-sidenav-open {
    .make-md-column(@columns-sidenav-open);
    .make-md-column-offset(3);
  }

  &.ph-sidenav-open-add, &.ph-sidenav-open-remove {
    transition:
      margin-left @ph-animation-duration ease-in-out,
      padding-left @ph-animation-duration ease-in-out,
      width @ph-animation-duration ease-in-out;
  }
}

.ph-sidenav-btn-collapse {
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  width: @ph-sidenav-small-width;
  height: @ph-sidenav-small-btn-height;
}

.ph-sidenav-item {
  margin-bottom: @padding-large-horizontal;
}

.ph-sidenav-header {
  margin-bottom: @padding-large-horizontal;
  h4, h5, h6 {
    margin-top: 0;
    margin-bottom: @padding-large-horizontal;
  }
  h6 {
    color: darken(@ph-background-color, 40%);
  }
}

@ph-split-icon-width: 20px;
@ph-split-date-width: 70px;
.ph-split-container {
  text-align: left;
  width: 100%;
  position: relative;
  padding-left: @padding-base-horizontal;
  padding-right: @padding-base-horizontal;

  &.ph-split-container-no-icon {
    padding-left: 3 * @padding-base-horizontal + @ph-split-icon-width + 1px;
  }

  &.ph-split-container-icon-right {
    padding-right: 2 * @padding-base-horizontal + @ph-split-icon-width;
  }

  .ph-split-icon {
    display: inline-block;
    width: @ph-split-icon-width + @padding-base-horizontal;
    text-align: center;
    padding-right: @padding-base-horizontal;
    .border-right(1px, solid, @gray);
    margin-right: @padding-base-horizontal;
  }

  // compensate for left and right icons
  // notice: width is relative to inner of button
  .ph-split-text {
    display: inline-block;
    max-width: 100%;
    padding-right: 2 * @padding-base-horizontal + @ph-split-icon-width + @ph-split-date-width;
  }
  &.ph-split-container-no-icon .ph-split-text {
    padding-right: @ph-split-date-width;
  }

  .ph-split-icon-right {
    position: absolute;
    right: @padding-base-horizontal;
    top: 50%;
    margin-top: -8px;
  }
}

.ph-sidenav-outline {
  max-height: 300px;
  overflow-y: auto;
}

.ph-dropdown-menu-full {
  .ph-dropdown-icon-left.fa.fa-fw {
    width: @ph-split-icon-width;
    margin-right: @padding-base-horizontal;
  }
}

.ph-dropdown-menu-active {
  font-weight: bold;
}

.ph-sidenav-journal {
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: top;
}

.ph-sidenav-publisher-link {
  font-size: @font-size-base - 2;
}

.ph-sidenav-accordion {
  .panel-heading {
    padding: 0;
    img {
      max-height: 20px;
      margin-top: -6px;
      margin-right: 4px;
    }
    a.accordion-toggle {
      display: block;
      background: @gray-lighter;
      padding: @padding-large-horizontal @padding-large-vertical;
      font-size: @font-size-base;
      color: @ph-base-color;
      outline: none;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        outline: none;
      }

    }

  }
}

// Pagination
.ph-sidenav-pagination {
  position: absolute;
  background: lighten(@gray-lighter, 3%);
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    li {
      flex: 1;
      position: relative;

      > a {
        display: block;
        border-bottom: 2px solid transparent;
        padding-top: @padding-base-horizontal;
        padding-bottom: @padding-base-horizontal;
        &:hover {
          background: @gray-lighter;
          border-bottom: 2px solid @ph-base-color;
        }
      }
    }
  }
}
.ph-sidenav-arrow {
  height: auto;
  top: 0;
  left: 50%;
  z-index: 10000;
}
.ph-sidenav-panel {
  position: absolute;
  background: @ph-white;
  box-shadow: @ph-shadow;
  width: 100%;
  bottom: 100%;
  left: 0;
  padding: @padding-base-horizontal;
}

.ph-sidenav-small {
  ul {
    display: block;
    li {
      > a {
        border-bottom: none;
        border-left: 2px solid transparent;
        padding-top: @padding-base-horizontal/2;
        padding-bottom: @padding-base-horizontal/2;
        &:hover {
          border-bottom: none;
          border-left: 2px solid @ph-base-color;
        }
      }
    }
  }
  .ph-sidenav-arrow {
    top: 50%;
    left: 100%;
  }
  .ph-sidenav-panel {
    left: 100%;
    bottom: 0;
    width: inherit;
  }
  .ph-sidenav-outline, .ph-sidenav-search {
    width: 25vw;
  }
}
// override padding for kudos so the iframe can fill the entire panel body
.ph-sidenav-info-kudos .panel-body {
  padding: 0;
}

.ph-sidenav-small {
  > .btn, .ph-hive-button-small .btn  {
    height: @ph-sidenav-small-btn-height;
    width: 100%;
    padding: 6px 10px;
  }
}

// overrides for hive-button
hive-button button:hover img {
  opacity: 0.7;
}
.ph-hive-button-large {
  img {
    margin-top: -4px;
    margin-bottom: -4px;
    height: 24px;
    vertical-align: text-bottom;
  }
}
.ph-hive-button-small img {
  width: 100%;
}
