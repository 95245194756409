.ph-tags {
  list-style-type: none;
  padding: 0px;
}
.ph-tags li a {
  width: 100%;
  font-size: small;
  font-weight: bold;
  text-align: left;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.ph-tags li .comment {
  background: #f0ad4e;
}
.ph-tags li a.edit {
  background: #d9534f;
}
.ph-tags li .question {
  background: #5cb85c;
}
.ph-tags li a.link {
  background: #5bc0de;
}
.ph-comment-tag {
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 5px;
  font-size: smaller;
  color: #999;
  margin-right: 10px;
}

