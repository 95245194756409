/* Colors */
/* Scaffolding */
/* Typography */
/* Iconography */
/* Components */
/* Tables */
/* Buttons */
/* Forms */
/* Dropdowns */
/* Media queries breakpoints */
/* Grid system */
/* Container sizes */
/* Navbar */
/* Navs */
/* Tabs */
/* Pills */
/* Pagination */
/* Pager */
/* Jumbotron */
/* Form states and alerts */
/* Popovers */
/* Labels */
/* Modals */
/* Alerts */
/* Progress bars */
/* List group */
/* Panels */
/* Thumbnails */
/* Wells */
/* Badges */
/* Breadcrumbs */
/* Carousel */
/* Close */
/* Code */
/* Type */
margin-cluster-placeholder {
  position: absolute;
  left: 15px;
  right: 15px;
}
margin-cluster-placeholder .ph-margin-cluster-placeholder-avatar {
  position: absolute;
  left: -50px;
  top: 0;
  width: 38px;
  height: 38px;
  background-color: #d6d6d6;
  border-radius: 50%;
}
margin-cluster-placeholder .ph-margin-cluster-placeholder-author {
  display: inline-block;
  width: 30%;
  height: 15px;
  background-color: #97e6fd;
}
margin-cluster-placeholder .ph-margin-cluster-placeholder-date {
  display: inline-block;
  width: 30%;
  height: 15px;
  background-color: #d6d6d6;
}
margin-cluster-placeholder .ph-margin-cluster-placeholder-title {
  width: 50%;
  height: 19px;
  background-color: #d6d6d6;
}
margin-cluster-placeholder .ph-margin-cluster-placeholder-body {
  height: 19px;
  background-color: #d6d6d6;
}
margin-cluster-placeholder .ph-margin-cluster-placeholder-more {
  height: 15px;
  width: 25%;
  background-color: #037292;
}
